import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/Layout"
import StyledHeading from "../components/StyledHeading"
import PostsList from "../components/PostsList"
import SEO from "../components/SEO"

const Template = ({ data, pageContext }) => {
  const { currentPage, numPages } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage =
    currentPage - 1 === 1 ? "/blog/" : `/blog/${(currentPage - 1).toString()}/`
  const nextPage = `/blog/${(currentPage + 1).toString()}/`

  return (
    <Layout>
      <SEO title="Alle blogs" description="" />
      <div className="container mx-auto mt-16 md:mt-20">
        <header className="ml-6">
          <StyledHeading tag="h1" text="Alle blogs" size="text-3xl" />
        </header>
        <PostsList posts={data.allMarkdownRemark} />
        <div className="grid grid-cols-3 mt-16">
          <div className="flex justify-end">
            {!isFirst && (
              <Link to={prevPage} rel="prev">
                ← Vorige
              </Link>
            )}
          </div>
          <div className="flex justify-around">
            {Array.from({ length: numPages }, (_, i) => (
              <Link
                key={`pagination-number${i + 1}`}
                to={`/blog/${i === 0 ? "" : `${i + 1}/`}`}
              >
                {i + 1}
              </Link>
            ))}
          </div>
          <div>
            {!isLast && (
              <Link to={nextPage} rel="next">
                Volgende →
              </Link>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        id
        excerpt(pruneLength: 150)
        frontmatter {
          date(formatString: "DD/MM/YYYY")
          slug
          title
          description
          featuredImage {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
export default Template
